.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
  }
  
  .page-nav-btn,
  .page-number-btn {
    border: 1px solid #d1d1d1;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .page-nav-btn:disabled,
  .page-number-btn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .page-number-btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .page-number-btn:hover:not(.active),
  .page-nav-btn:hover:not(:disabled) {
    background-color: #e6e6e6;
  }
  