* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.form-row {
    margin-top: 3% !important;
    padding: 4%;
    background: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.form-item {
    margin-bottom: 2%;
}

.form-item label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.form-item input, .form-item select {
    width: 100%;
    padding: 2%;
    border: 1px solid rgb(184, 182, 182);
    border-radius: 10px;
}

form h5 {
    margin: 3% 0;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.form-button {
    display: flex;
    justify-content: center;
    padding-top: 3%;
}

.form-button button {
    padding: 8px 35px;
    background: #007BFF;
    color: #fff;
    border: 1px solid #007BFF;
    border-radius: 6px;
}